var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.ready)?_c('v-container',{staticClass:"mb-12",staticStyle:{"max-width":"960px"}},[_c('v-data-table',{staticClass:"transparent mb-12 pb-12",attrs:{"headers":_vm.headers,"items":_vm.filteredItems,"search":_vm.search,"page":_vm.page,"show-select":"","fixed-header":""},on:{"update:page":function($event){_vm.page=$event},"click:row":function($event){return _vm.edit($event)}},scopedSlots:_vm._u([{key:"item.selected",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"hide-details":"","label":"","dense":""},on:{"click":function($event){$event.stopPropagation();return _vm.selectItem($event)}},model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}})]}},{key:"item.isMaster",fn:function(ref){
var item = ref.item;
return [(item.isMaster)?_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" mdi-alpha-m-box ")]):_vm._e()]}},{key:"item.isSlave",fn:function(ref){
var item = ref.item;
return [(item.isSlave)?_c('v-icon',{attrs:{"small":"","color":"#999"}},[_vm._v(" mdi-alpha-s-box ")]):_vm._e()]}},{key:"footer.prepend",fn:function(){return [_c('h5',{staticClass:"mr-8"},[_c('small',[_vm._v(_vm._s(_vm.footprint))])]),_c('span',{staticClass:"mx-3"},[_vm._v("Total selected buildings: "+_vm._s(_vm.selectedBuildingsNumber))])]},proxy:true},{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"transparent",attrs:{"flat":""}},[_c('v-text-field',{staticClass:"mr-4",staticStyle:{"display":"inline-block","width":"200px"},attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","dense":"","outlined":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.selects.length)?_c('v-btn',{staticClass:"mb-2",attrs:{"text":"","color":"primary"},on:{"click":_vm.updateGroup}},[_c('v-icon',[_vm._v("mdi-pencil-box-multiple-outline")]),_vm._v(" Group update ")],1):_vm._e(),_c('v-btn',{staticClass:"mb-2",attrs:{"text":""},on:{"click":_vm.refresh}},[_c('v-icon',[_vm._v("mdi-refresh")]),_vm._v(" Refresh ")],1),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"text":"","color":"primary"},on:{"click":_vm.createBuilding}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-plus")]),_vm._v(" New building ")],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteBuilding(item)}}},[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v("mdi-delete")])],1)]}}],null,false,3801374897),model:{value:(_vm.selects),callback:function ($$v) {_vm.selects=$$v},expression:"selects"}}),(_vm.selects.length)?_c('GroupUpdate',{attrs:{"dialog":_vm.groupUpdate,"items":_vm.selects,"groupUpdated":_vm.groupUpdated},on:{"update:dialog":function($event){_vm.groupUpdate=$event},"update:items":function($event){_vm.selects=$event},"update:groupUpdated":function($event){_vm.groupUpdated=$event},"update:group-updated":function($event){_vm.groupUpdated=$event}}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }